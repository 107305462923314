<template>
  <div class="container">
    <div class="d-flex justify-content-center mt-5">
      <div class="col-md-5">
        <h1>Beta Tester Application!</h1>
        <p class="text-muted">Guard.gg is a new US based marketplace for TF2, CS:GO, and Rust, and we need your help testing! We are giving free site balance to users who beta test our website. Just sign up for a Guard.gg account, make sure to attach your Steam account, and write a brief message below on your experience buying, selling, or trading skins.</p>
        <div class="w-100">
          <form>
            <div class="form-group">
              <label for="">Email</label>
              <input class="form-control" type="text" name="" id="" v-model="email">
            </div>
            <div class="form-group">
              <label for="">Feedback Category</label>
              <select class="form-control" v-model="feedbackCategory">
                <option selected value="Beta Testing">Beta Testing</option>
                <!-- <option value="Improvement">Improvement</option>
                <option value="Complaint">Complaint</option> -->
              </select>
            </div>
            
            <div class="form-group">
              <label for="">Message</label>
              <textarea v-model="content" class="form-control" name="" id="" cols="30" rows="10"></textarea>
              
            </div>
            <a href="/Beta-Terms.pdf" target="_blank">Terms and Conditions</a>
            <div class="mt-3 d-flex justify-content-between">
              <div>
                <p class="text-muted">{{remainingChars}} characters remaining</p>
              </div>

              <button
                class="btn btn-orange"
                type="button"
                @click="submit"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const api = require("../api");
import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      content: "",
      feedbackCategory: "Beta Testing",
      email: ""
      
    };
  },
  computed: {
    remainingChars() {
      return 300 - this.content.length
    }
  },
  methods: {
    async submit() {
      Swal.showLoading()
      try {
        if (!this.content || !this.feedbackCategory || !this.email) {
          await Swal.fire("Oops", "Please fill out all fields.", "error");
          return;
        }

        await api.post(`/feedback`, {
          content: this.content,
          category: this.feedbackCategory,
          email: this.email,
        });
        await Swal.fire("Thanks", "We’ve received your response, and will respond via email with further details!", "success");
      } catch (e) {
        await Swal.fire("Its not you, its us.", "We're unable to recevie your message right now, please try again later.", "error");
      }
    }
  },
  mounted() {
  },
  watch: {
  },
};
</script>

<style lang="scss">
.input-fix {
  border-radius: 0 !important;
}
</style>
